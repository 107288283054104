import "./App.css";
import React from "react";
import { ConfigProvider } from "antd";
import { Layout, Menu, FloatButton, Flex } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Main from "./pages/main_search";
import Concept from "./pages/concept";
import About from "./pages/about";

const { Header, Content, Footer } = Layout;
const items = [
  {
    key: "home",
    label: <a href="/">Home</a>,
  },
  {
    key: "search",
    label: <a href="/concepts">Search</a>,
  },
  {
    key: "ohdsi",
    label: (
      <a
        href="https://ohdsi-ai-gpt-4o-vectorsimplehybrid.azurewebsites.net/"
        target="blank"
        rel="noopener noreferrer"
      >
        OHDSI AI
      </a>
    ),
  },
  {
    key: "about",
    label: <a href="/about">About Us</a>,
  },
  // {
  //   key: "faq",
  //   label: "FAQ",
  // },
];

function App() {
  const path = window.location.href;
  const activeMenu = path.includes("concept")
    ? "search"
    : path.includes("about")
    ? "about"
    : path.includes("faq")
    ? "faq"
    : "home";
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
          colorPrimary: "#b85063",
          colorInfo: "#d21243",
          colorSuccess: "#1cb6b6",
          colorError: "#d21243",
          colorWarning: "#f29027",
          wireframe: false,
          colorBorder: "rgb(5 5 5 / 6%)",
        },
        components: {
          Menu: {
            itemHeight: 42,
            itemPaddingInline: 18,
            horizontalLineHeight: 42,
            itemHoverColor: "#874a55",
            activeBarBorderWidth: 0,
          },
          Layout: {
            headerBg: "#f5f5f7",
            headerHeight: 40,
            headerPadding: "0 0",
            footerBg: "#FFF",
            footerPadding: "12px 12px",
          },
          Collapse: {
            headerBg: "rgba(0, 0, 0, 0.02)",
          },
          Descriptions: {
            titleMarginBottom: 6,
            titleColor: "#b85063",
          },
        },
      }}
    >
      <Layout className="App">
        <Header
          style={{
            position: "fixed",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "100%",
              borderBottom: "1px solid rgba(5, 5, 5, 0.06)",
              position: "relative",
            }}
          >
            <a href="/">
              <img
                src={
                  "https://attract-dmp.s3.ap-southeast-1.amazonaws.com/media/logo192.png"
                }
                alt="Logo"
                height={"30vmin"}
                style={{
                  position: "absolute",
                  margin: "auto",
                  top: 0,
                  bottom: 0,
                  paddingLeft: 12,
                }}
              />
            </a>
          </div>
          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={[activeMenu]}
            items={items}
            style={{
              flex: 1,
              minWidth: 0,
              justifyContent: "flex-end",
              backgroundColor: "#f5f5f7",
            }}
          />
        </Header>
        <Content
          className="App-content"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <a
            href="https://ohdsi-ai-gpt-4o-vectorsimplehybrid.azurewebsites.net/"
            target="blank"
            rel="noopener noreferrer"
          >
            <FloatButton
              //icon={<MessageOutlined />}
              description={
                <Flex align="center" justify="center">
                  <img
                    src="https://www.ohdsi.org/wp-content/uploads/2015/02/h243-ohdsi-logo-with-text.png"
                    style={{
                      width: 50,
                    }}
                    alt="ohdsi"
                  />
                  <span
                    style={{
                      fontSize: 12.5,
                      fontWeight: 600,
                      color: "#20425a",
                    }}
                  >
                    AI
                  </span>
                  <CommentOutlined
                    style={{
                      fontSize: 12.5,
                      marginLeft: 3,
                      color: "#20425a",
                    }}
                  />
                </Flex>
              }
              shape="square"
              style={{ right: 24, width: 90 }}
            />
          </a>
          <Router>
            <Routes>
              <Route key="main" path="" element={<Home />} />
              <Route key="home" path="/home" element={<Home />} />
              <Route key="concepts" path="/concepts" element={<Main />} />
              <Route key="about" path="/about" element={<About />} />
              <Route
                key="concept"
                path="/concept"
                element={<Concept />}
              ></Route>
              <Route
                key="concept_id"
                path="/concept/:id"
                element={<Concept />}
              ></Route>
            </Routes>
          </Router>
        </Content>
        <Footer
          style={{
            textAlign: "center",
            backgroundColor: "#f5f5f7",
          }}
        >
          ATTRaCT ©{new Date().getFullYear()} Created with{" "}
          <span style={{ color: "#e91e63" }}>♥</span> by SICS Data Team
        </Footer>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
