import React, { useState, useEffect } from "react";
import { client } from "../services/menu.service";
import { useParams } from "react-router-dom";
import { titleCase } from "../utils/utils";

import {
  Layout,
  Row,
  Col,
  Descriptions,
  Button,
  Typography,
  Tag,
  Flex,
  theme,
  Table,
} from "antd";
const { Content } = Layout;
const { Title } = Typography;

function Concept() {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const concept_id = useParams().id;
  const [status, setStatus] = useState(false);
  const [conceptInfo, setConceptInfo] = useState({});
  const [relInfo, setRelInfo] = useState([]);

  var content = (
    <Title level={5} type="danger">
      This variable/value does not exist! Please try again!
    </Title>
  );
  useEffect(() => {
    if (concept_id) {
      client
        .get(`/concepts?id=${concept_id}`)
        .then(function (res) {
          const v = res.data[0];
          var tpTags = v.timepoint.split(";").map(function (tp) {
            return (
              <Tag key={tp} color="red" className="tp-tag">
                {tp}
              </Tag>
            );
          });
          var conInfo = [
            {
              key: 0,
              label: "Record Type",
              children: titleCase(v.concept_type),
              span: 4,
            },
            {
              key: 1,
              label: v.concept_type === "variable" ? "Variable ID" : "Value",
              children: v.name,
              span: 4,
            },
            {
              key: 2,
              label: "OMOP Concept ID",
              children: v.concept_id,
              span: 4,
            },
            {
              key: 3,
              label: "OMOP Domain",
              children: titleCase(v.domain.replace("_", " ")),
              span: 4,
            },
            {
              key: 4,
              label: "OMOP Vocabulary",
              children: v.vocablabel,
              span: 4,
            },
            { key: 5, label: "OMOP Name", children: v.concept_name, span: 4 },
            { key: 6, label: "Description", children: v.description, span: 4 },
          ];
          if (v.timepoint) {
            conInfo.push({
              key: 7,
              label: "Frequency",
              children: (
                <Flex gap={1} wrap>
                  {tpTags}
                </Flex>
              ),
              span: 4,
            });
          }
          if (v.concept_type === "variable") {
            conInfo.push({
              key: 8,
              label: "Data Type",
              children: titleCase(v.data_type),
              span: 4,
            });
            if (v.unit) {
              conInfo.push({
                key: 9,
                label: "Unit",
                children: v.unit,
                span: 4,
              });
            }
            if (v.range) {
              conInfo.push({
                key: 10,
                label: "Range",
                children: v.range,
                span: 4,
              });
            }
            if (v.format) {
              conInfo.push({
                key: 11,
                label: "Format",
                children: v.format,
                span: 4,
              });
            }
            if (v.categories) {
              var cats = v.categories.split("|").map(function (d) {
                return <li key={d}>{d}</li>;
              });
              conInfo.push({
                key: 12,
                label: "Categories",
                children: (
                  <ul style={{ paddingInlineStart: 12, margin: 0 }}>{cats}</ul>
                ),
                span: 4,
              });
            }
          }
          setConceptInfo(conInfo);
          setStatus(true);
          var rel_data = [];
          if (v.related) {
            var f =
              "query={id,concept_name,concept_id,name,description,concept_type}";
            client
              .get(`/concepts?id__in=${v.related.split("|").join(",")}&${f}`)
              .then(function (r) {
                rel_data = r.data.map(function (d) {
                  d.key = d.id;
                  if (d.concept_type == "value") {
                    d.relationship = "Category/Value";
                  } else {
                    d.relationship = "Variable";
                  }
                  return d;
                });
                rel_data = rel_data.sort((a, b) => a.name - b.name);
                setRelInfo(rel_data);
              });
          }
          client
            .get(`/concepts?concept_id=${v.concept_id}&${f}`)
            .then(function (r) {
              if (r.data.length > 0) {
                var sim_data = [];
                r.data.map(function (d, i) {
                  if (d.id !== concept_id) {
                    d.key = d.id + i;
                    d.relationship =
                      d.concept_type === "variable"
                        ? "Similar Variable"
                        : "Similar Value";
                    sim_data.push(d);
                  }
                });
                setRelInfo([...rel_data, ...sim_data]);
              }
            });
        })
        .catch(function (error) {
          console.log(error);
          setStatus(false);
          setRelInfo([]);
        });
    }
  }, []);

  const relCols = [
    {
      key: "relationship",
      title: "Relationship",
      dataIndex: "relationship",
    },
    {
      key: "name",
      title: "Variable/Value",
      dataIndex: "name",
      render: function (value, record) {
        return <a href={`/concept/${record.id}`}>{value}</a>;
      },
    },
    {
      key: "concept_id",
      title: "Concept ID",
      dataIndex: "concept_id",
    },
    {
      key: "concept_name",
      title: "Name",
      dataIndex: "concept_name",
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
    },
  ];

  if (status) {
    content = (
      <Row gutter={16} style={{ width: "100%" }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 11 }}
          xl={{ span: 11 }}
          xxl={{ span: 11 }}
        >
          <Title level={5} style={{ color: colorPrimary }}>
            Details
          </Title>
          <Descriptions
            column={4}
            bordered
            items={conceptInfo}
            size="small"
            labelStyle={{
              minWidth: 150,
              fontWeight: 550,
            }}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 13 }}
          xl={{ span: 13 }}
          xxl={{ span: 13 }}
          style={{ padding: "0px 8px" }}
        >
          <Title level={5} style={{ color: colorPrimary }}>
            Related Variables / Values
          </Title>
          <Table
            columns={relCols}
            dataSource={relInfo}
            minWidth="100%"
            size="small"
            pagination={{
              defaultPageSize: 15,
              hideOnSinglePage: true,
              position: ["bottomRight"],
              showTotal: (total) => `Total ${total} Records`,
            }}
            bordered
          />
        </Col>
      </Row>
    );
  }

  return (
    <Layout>
      <Content
        style={{
          marginTop: 40,
          backgroundColor: "#fff",
        }}
      >
        <Row
          style={{
            padding: 12,
          }}
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
            xxl={{ span: 20, offset: 2 }}
          >
            <Button type="primary">
              <a href="/concepts">Back to Search</a>
            </Button>
            {content}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Concept;
