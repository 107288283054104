export const removeFromArray = function (array, ...deleteElement) {
    for (let element of deleteElement) {
        if (array.includes(element)) {
            array.splice(array.indexOf(element), 1);
        }
    }
    return array;
};

export const safeString = function (text) {
    if (text) {
        text = text.replace('<', '').replace('>', '');
    }
    return text
}

export const titleCase = function(str) {
    return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
  }