import React, { useState, useEffect } from "react";
import { client } from "../services/menu.service";
import { Layout, Row, Col, Typography, theme } from "antd";
import parse from 'html-react-parser';
const { Content } = Layout;
const { Title } = Typography;


function About() {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const [arContent, setArcontent] = useState("");
  useEffect(() => {
    client.get("/articles?id=about").then(function (res) {
      setArcontent(res.data[0].content);
    });
  }, []);
  return (
    <Layout>
      <Content
        style={{
          marginTop: 40,
          backgroundColor: "#fff",
        }}
      >
        <Row
          style={{
            padding: 18,
          }}
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 20, offset: 2 }}
            lg={{ span: 16, offset: 4 }}
            xl={{ span: 14, offset: 5 }}
            xxl={{ span: 14, offset: 5 }}
          >
            <Title level={2} style={{ color: colorPrimary, marginTop: 36 }}>
              The Asian neTwork for Translational Research and Cardiovascular
              Trials (ATTRaCT)
            </Title>
            <div style={{fontSize: "0.85rem", marginTop: 28, lineHeight: "1.5rem"}}>
            {parse(arContent)}
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default About;
