import React, { useState, useEffect } from "react";
import { client } from "../services/menu.service";
import { safeString, titleCase } from "../utils/utils";
import {
  Layout,
  Input,
  Flex,
  Typography,
  Button,
  Collapse,
  Checkbox,
  Tag,
  Table,
  theme,
  Popover,
} from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";

const { Header, Content, Sider } = Layout;
const { Search } = Input;
const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;

const facetChoices = [
  { key: "domain", label: "DOMAIN" },
  { key: "data_type", label: "DATA TYPE" },
  { key: "vocab", label: "VOCAB" },
  { key: "concept_type", label: "RECORD TYPE" },
  { key: "timepoint", label: "TIMEPOINT" },
];

function buildQuery(checked_list, params) {
  var queryString = [];
  if (typeof params === "string") {
    queryString.push(`search=${params}`);
  } else if (params.get("search")) {
    queryString.push(`search=${params.get("search")}`);
  }
  Object.entries(checked_list).forEach(function ([key, values]) {
    var k = key;
    if (key === "ctype") {
      k = "concept_type";
    } else if (key === "type") {
      k = "data_type";
    }

    if (values.length > 0 && values.length < 2) {
      queryString.push(`${k}=${values[0]}`);
    } else if (values.length > 1) {
      if (k !== "timepoint") {
        queryString.push(`${k}__in=${values.join(",")}`);
      } else {
        queryString.push(`${k}=${values.join(",")}`);
      }
    }
  });
  return queryString.join("&");
}

function Main() {
  const {
    token: { colorWarning, colorPrimary },
  } = theme.useToken();

  const siderCollapsedWidth = 0;
  const siderExpandWidth = 250;
  const facetList = ["domain", "type", "vocab", "ctype", "timepoint"];
  const [collapsed, setCollapsed] = useState(false);
  const [facets, setFacets] = useState([]);
  const [facetdict, setFacetdict] = useState({});
  const [concepts, setConcepts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  var iniSearch = {};
  facetList.map(function (d) {
    if (searchParams.get(d)) {
      iniSearch[d] = searchParams.get(d).split(",");
    }
    return true;
  });

  const [checkedList, setCheckedList] = useState(iniSearch);
  const [searchString, setSearchString] = useState(
    buildQuery(iniSearch, searchParams)
  );

  useEffect(() => {
    var summary_url = searchString ? `/summary?${searchString}` : "/summary";
    var concept_url = searchString ? `/concepts?${searchString}` : "/concepts";
    client.get(summary_url).then(function (res) {
      var dt = {};
      var f = res.data.map(function (d) {
        dt[d.value] = d.label;
        d.label = `${d.label} (${d.records.toLocaleString()})`;
        return d;
      });
      setFacets(f);
      setFacetdict(dt);
    });
    client
      .get(concept_url)
      .then(function (res) {
        setConcepts(res.data);
        setLoading(false);
      })
      .catch(function (error) {
        setConcepts([]);
        setLoading(false);
      });
  }, [searchString]);

  const conceptCols = [
    {
      title: (
        <Flex gap={3}>
          <span>Variable/Value</span>
          <Popover content={"Variable Name or Value"}>
            <InfoCircleTwoTone twoToneColor={colorPrimary} />
          </Popover>
        </Flex>
      ),
      dataIndex: "name",
      key: "name",
      width: 120,
      fixed: "left",
    },
    {
      title: "OMOP Concept ID",
      dataIndex: "concept_id",
      key: "concept_id",
      width: 80,
      align: "center",
    },
    {
      title: "OMOP Name",
      dataIndex: "concept_name",
      key: "concept_name",
      width: 200
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
      render: function(value, record) {
        return <a href={`/concept/${record.key}`} style={{fontWeight: 450}}>{value}</a>
      }
    },
    {
      title: "OMOP Domain",
      dataIndex: "domain",
      key: "domain",
      render: (value) => titleCase(value.replace('_', ' ')),
      width: 100,
      align: "center",
    },
    {
      title: (
        <Flex gap={3}>
          <span>Record Type</span>
          <Popover
            content={"Whether the record is a variable or a variable value"}
          >
            <InfoCircleTwoTone twoToneColor={colorPrimary} />
          </Popover>
        </Flex>
      ),
      dataIndex: "concept_type",
      key: "concept_type",
      render: (value) => titleCase(value),
      width: 80,
      align: "center",
    },
    {
      title: "OMOP Vocabulary",
      dataIndex: "vocab",
      key: "vocab",
      width: 110,
      align: "center",
      render: (v, record) => record.vocablabel,
    },
    {
      title: "Data Type",
      dataIndex: "data_type",
      key: "data_type",
      width: 80,
      align: "center",
      render: (value) => titleCase(value)
    },
    {
      title: (
        <Flex gap={3}>
          <span>Frequency</span>
          <Popover
            content={
              <div>
                <span style={{ fontWeight: 500 }}>Visit Frequency</span>
                <ul style={{ paddingInlineStart: 12, marginTop: 6 }}>
                  <li>BL: Baseline</li>
                  <li>WK6: 6 week</li>
                  <li>M6: 6 month</li>
                  <li>Y1: 1 year follow-up</li>
                  <li>Y2: 2 year follow-up</li>
                  <li>Y3: 3 year follow-up</li>
                </ul>
              </div>
            }
          >
            <InfoCircleTwoTone twoToneColor={colorPrimary} />
          </Popover>
        </Flex>
      ),
      dataIndex: "timepoint",
      key: "timepoint",
      width: 120,
      render: function (tps) {
        var tpTags = tps.split(";").map(function (tp) {
          return (
            <Tag key={tp} color="red" className="tp-tag">
              {tp}
            </Tag>
          );
        });
        return (
          <Flex gap={1} wrap>
            {tpTags}
          </Flex>
        );
      },
    },
  ];

  const items = facetChoices.map(function (d) {
    return {
      key: d.key,
      label: (
        <Flex
          justify="start"
          align="center"
          style={{ fontWeight: 500, fontSize: "0.8rem" }}
        >
          <span
            className={`at-facet-title__badge at-facet-title__badge--${d.key}`}
          ></span>
          {d.label}
        </Flex>
      ),
      children: (
        <CheckboxGroup
          key={d.key}
          className="menu-collapse-checkbox"
          style={{ display: "grid" }}
          options={facets.filter((f) => f.facet === d.key)}
          value={checkedList[d.key]}
          onChange={function (value) {
            var dt = {};
            var newParams = {};
            dt[d.key] = value;
            setCheckedList({ ...checkedList, ...dt });
            setLoading(true);
            setSearchString(
              buildQuery({ ...checkedList, ...dt }, searchParams)
            );
            Object.entries({ ...checkedList, ...dt }).forEach(function ([
              k,
              values,
            ]) {
              newParams[k] = values.join(",");
              if (searchParams.get("search")) {
                newParams["search"] = searchParams.get("search");
              }
            });
            setSearchParams(newParams);
          }}
        />
      ),
    };
  });

  const filterTags = [];
  Object.entries(checkedList).forEach(function ([k, values]) {
    var color =
      k === "domain"
        ? "#e91e63"
        : k === "vocab"
        ? colorWarning
        : k === "type"
        ? "#00bcd4"
        : k === "ctype"
        ? "#673AB7"
        : "#4CAF50";
    values.map(function (d) {
      var label = facetdict[d];
      filterTags.push(
        <Tag
          key={d}
          color={color}
          className="filter-tag"
          onClose={function (e) {
            var dt = {};
            dt[k] = checkedList[k].filter((v) => v !== d);
            setCheckedList({ ...checkedList, ...dt });
            setSearchString(buildQuery({ ...checkedList, ...dt }, searchParams));
          }}
          closeIcon
        >
          {label}
        </Tag>
      );
      return true;
    });
  });
  const filterTagDiv =
    filterTags.length > 0 ? (
      <Flex gap="4px 0" style={{ padding: "20px 8px" }} wrap>
        {filterTags}
      </Flex>
    ) : (
      ""
    );

  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          top: 40,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#a6263e",
        }}
      >
        <Flex
          align="center"
          justify="space-around"
          style={{
            width: "100%",
            paddingInline: 24,
          }}
        >
          <Text
            level={2}
            style={{
              color: "#fff",
              fontWeight: 600,
              fontSize: "0.85rem",
              width: `calc(${siderExpandWidth}px + 18px)`,
            }}
          >
            Search by Keyword
          </Text>
          <Search
            size="middle"
            defaultValue={safeString(searchParams.get("search"))}
            placeholder="Search variables/values..."
            onSearch={function (value) {
              setLoading(true);
              if (value) {
                setSearchParams({ search: safeString(value) });
                setSearchString(buildQuery({}, safeString(value)));
              } else {
                setSearchParams({});
                setSearchString("");
              }
            }}
          />
        </Flex>
      </Header>
      <Content
        style={{
          padding: 0,
        }}
      >
        <Layout hasSider>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={siderCollapsedWidth}
            width={siderExpandWidth}
            theme="light"
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 80,
              bottom: 0,
              borderInlineEnd: "1px solid rgb(5 5 5 / 6%)",
            }}
          >
            <Flex
              align="center"
              justify="space-between"
              style={{ flexDirection: "column", height: "80vh" }}
              vertical
            >
              <div style={{ width: "100%" }}>
                {filterTagDiv}
                <Collapse
                  items={items}
                  className="menu-collapse"
                  bordered={false}
                  size="small"
                  expandIconPosition="end"
                  style={{ width: "100%" }}
                  accordion
                />
              </div>
              <Button
                style={{ width: "85%" }}
                onClick={(e) => {
                  if (Object.keys(checkedList).length > 0) {
                    setCheckedList({});
                    setSearchString(buildQuery({}, searchParams));
                    if (searchParams.get("search")) {
                      setSearchParams({ search: searchParams.get("search") });
                    } else {
                      setSearchParams({});
                    }
                    setLoading(true);
                  }
                }}
              >
                Clear Filters
              </Button>
            </Flex>
          </Sider>
          <Layout
            className="sider-main-content"
            style={{
              marginLeft: collapsed ? siderCollapsedWidth : siderExpandWidth,
              overflow: "hidden",
              padding: 0,
              backgroundColor: "#fff"
            }}
          >
            <Header
              style={{
                padding: "0px 8px 0px 0px",
                background: "#fff",
                height: "30px",
                lineHeight: "30px",
                zIndex: 1,
                position: "fixed",
                top: 80,
                left: collapsed ? siderCollapsedWidth : siderExpandWidth,
                width: collapsed
                  ? "calc(100%)"
                  : `calc(100% - ${siderExpandWidth}px)`
              }}
            >
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 30,
                  height: 30,
                }}
              />
            </Header>
            <Content
              style={{
                overflow: "hidden",
                backgroundColor: "#fff",
                padding: "0px 12px 30px 12px",
                marginTop: 110,
              }}
            >
              <Table
                key="concept-table"
                columns={conceptCols}
                dataSource={concepts}
                pagination={{
                  defaultPageSize: 15,
                  hideOnSinglePage: false,
                  position: ["topRight"],
                  showTotal: (total) => `Total ${total} Records`,
                }}
                scroll={{ y: "63vh", x: "100%" }}
                size="middle"
                loading={{
                  spinning: loading,
                  tip: "Loading...",
                  wrapperClassName: "table-spin",
                }}
              />
            </Content>
          </Layout>
        </Layout>
      </Content>
    </Layout>
  );
}

export default Main;
