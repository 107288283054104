import React, { useState, useEffect } from "react";
import { client } from "../services/menu.service";
import { safeString } from "../utils/utils";
import { Input, Card, Row, Col, Flex, Typography, Avatar, theme } from "antd";
import { useNavigate} from "react-router-dom";
const { Search } = Input;
const { Title, Text } = Typography;

function Home() {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);

  useEffect(() => {
    client
      .get("/domains")
      .then(function (res) {
        setRecords(res.data);
      })
      .catch(function (error) {
        setRecords([]);
      });
  }, []);

  const cards = records.map(function (d) {
    return (
      <Col
        key={d.key}
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 12 }}
        lg={{ span: 8 }}
      >
        <a href={`/concepts?domain=${d.key}`}>
        <Card size="middle">
          <Flex align="center" justify="space-between">
            <div>
              <Title level={4} style={{ color: colorPrimary, margin: 0 }}>
                {d.key !== "geography" ? `${d.label}s`: `${d.label}`}
              </Title>
              <Text>
                <span style={{ fontWeight: 500 }}>
                  {d.records.toLocaleString()}{" "}
                </span>
                records
              </Text>
            </div>
            <Avatar
              shape="square"
              size={45}
              src={d.img}
              style={{ backgroundColor: "#f8e8e9" }}
            />
          </Flex>
        </Card>
        </a>
      </Col>
    );
  });
  return (
    <div width="100%">
      <Flex
        align="center"
        className="hero-banner"
        style={{
          marginBottom: "5vmin",
          backgroundColor: "#f8e9ec",
          height: 360,
          padding: 18,
        }}
      >
        <Row
          style={{
            margin: "auto",
            minWidth: "100%",
          }}
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 20, offset: 2 }}
            lg={{ span: 16, offset: 4 }}
            xl={{ span: 14, offset: 5 }}
            xxl={{ span: 14, offset: 5 }}
          >
            <Title
              level={1}
              style={{ color: colorPrimary, marginBottom: 24, marginTop: 0 }}
            >
              ATTRaCT Data Platform
            </Title>
            <Search
              key='search-box'
              size="large"
              placeholder="Search variables/values..."
              onSearch={function(value) {
                navigate(`/concepts?search=${safeString(value)}`);
              }}
              enterButton
            />
          </Col>
        </Row>
      </Flex>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 20, offset: 2 }}
        lg={{ span: 16, offset: 4 }}
        xl={{ span: 14, offset: 5 }}
        xxl={{ span: 14, offset: 5 }}
      >
        <Row gutter={[8, 18]} width="100%" style={{ padding: 8 }}>
          {cards}
        </Row>
      </Col>
    </div>
  );
}

export default Home;
